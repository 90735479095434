<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tableHistories.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group>
            <label for="date_from">
              <strong>Date From</strong>
            </label>
            <b-input
              id="date_from"
              v-model="tableHistories.filter.date_from"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group>
            <label for="date_from">
              <strong>Date To</strong>
            </label>
            <b-input
              id="date_from"
              v-model="tableHistories.filter.date_to"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group>
            <label>
              <strong>Status</strong>
            </label>
            <b-select
              v-model="tableHistories.filter.status"
              :options="list.statuses"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableHistories.perPage"
        :current-page="tableHistories.currentPage"
        :items="tableProvider"
        :fields="tableHistories.fields"
        :sort-by.sync="tableHistories.sortBy"
        :sort-desc.sync="tableHistories.sortDesc"
        :sort-direction="tableHistories.sortDirection"
        :filter="tableHistories.filter"
        :filter-included-fields="tableHistories.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableHistories.currentPage * tableHistories.perPage - tableHistories.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(departure)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.departure_date} ${data.item.departure_time}`) }}
          </div>
        </template>

        <template #cell(arrival)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.arrival_date} ${data.item.arrival_time}`) }}
          </div>
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onView(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableHistories.perPage"
              :options="tableHistories.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableHistories.currentPage"
            :total-rows="tableHistories.totalRows"
            :per-page="tableHistories.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      aria-hidden="false"
      modal-class="full-height"
      title="View Detail"
    >
      <b-card no-body>
        <form
          novalidate
          role="form"
        >
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-success"
            content-class="scrollable"
          >
            <b-tab
              active
            >
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-file-text-line h4" />
                  <b class="is-tab-active ml-1">
                    Details
                  </b>
                </div>
              </template>
              <b-row class="p-2">

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="reserved_at">
                      <strong>Reserved At</strong>
                    </label>
                    <b-input
                      id="reserved_at"
                      v-model="reservationHistory.created_at"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="status">
                      <strong>Status</strong>
                    </label>
                    <b-input
                      id="status"
                      v-model="reservationHistory.status"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group v-if="reservationHistory.status === 'Cancelled'">
                    <label for="cancellation_reason">
                      <strong>Cancellation Reason</strong>
                    </label>
                    <b-textarea
                      id="cancellation_reason"
                      v-model="reservationHistory.cancellation_reason"
                      type="text"
                      rows="6"
                      max-rows="6"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="company">
                      <strong>Company</strong>
                    </label>
                    <b-input
                      id="company"
                      v-model="reservationHistory.company_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="organization">
                      <strong>Organization</strong>
                    </label>
                    <b-input
                      id="organization"
                      v-model="reservationHistory.organization_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="reservation_type">
                      <strong>Reservation Type</strong>
                    </label>
                    <b-input
                      id="reservation_type"
                      v-model="reservationHistory.reservation_type"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group>
                    <label for="passenger_count">
                      <strong>Passenger Count</strong>
                    </label>
                    <b-input
                      id="passenger_count"
                      v-model="reservationHistory.passenger_count"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="vehicle_type">
                      <strong>Vehicle Type</strong>
                    </label>
                    <b-input
                      id="vehicle_type"
                      v-model="reservationHistory.vehicle_type_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="departure">
                      <strong>Departure</strong>
                    </label>
                    <b-input
                      id="departure"
                      v-model="departureDateTime"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="arrival">
                      <strong>Arrival</strong>
                    </label>
                    <b-input
                      id="arrival"
                      v-model="arrivalDateTime"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="purpose">
                      <strong>Purpose</strong>
                    </label>
                    <b-textarea
                      id="purpose"
                      v-model="reservationHistory.purpose"
                      type="text"
                      rows="6"
                      max-rows="6"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group v-if="reservationHistory.status !== 'Cancelled'">
                    <label for="processed_at">
                      <strong>Processed At</strong>
                    </label>
                    <b-input
                      id="processed_at"
                      v-model="reservationHistory.processed_at"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group v-if="reservationHistory.status !== 'Cancelled'">
                    <label for="processed_by_name">
                      <strong>Processed By</strong>
                    </label>
                    <b-input
                      id="processed_by_name"
                      v-model="reservationHistory.processed_by_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group v-if="reservationHistory.status !== 'Cancelled'">
                    <label for="remarks">
                      <strong>Processed Remarks</strong>
                    </label>
                    <b-textarea
                      id="remarks"
                      v-model="reservationHistory.processed_remarks"
                      type="text"
                      rows="6"
                      max-rows="6"
                      disabled
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab
              v-if="reservationHistory.attachments.length > 0"
            >
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-attachment-line h4" />
                  <b class="is-tab-active ml-1">
                    Attachments
                  </b>
                </div>
              </template>

              <b-table
                class="mt-1"
                :fields="tableAttachments.fields"
                :items="reservationHistory.attachments"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-tab>

            <b-tab
              v-if="reservationHistory.status === 'Approved' && isDriverVisible"
            >
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-user-forbid-line h4" />
                  <b class="is-tab-active ml-1">
                    Assigned Driver
                  </b>
                </div>
              </template>

              <b-row class="p-2">

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="driver_name">
                      <strong>Driver Name</strong>
                    </label>
                    <b-input
                      id="driver_name"
                      v-model="reservationHistory.driver_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="driver_license_type">
                      <strong>License Type</strong>
                    </label>
                    <b-input
                      id="driver_license_type"
                      v-model="reservationHistory.driver_license_type"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="driver_license_number">
                      <strong>License Number</strong>
                    </label>
                    <b-input
                      id="driver_license_number"
                      v-model="reservationHistory.driver_license_number"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="driver_license_expiry">
                      <strong>License Expiry</strong>
                    </label>
                    <b-input
                      id="driver_license_expiry"
                      v-model="reservationHistory.driver_license_expiry"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="reservationHistory.status === 'Approved' && isVehicleVisible"
            >
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-roadster-line h4" />
                  <b class="is-tab-active ml-1">
                    Assigned Vehicle
                  </b>
                </div>
              </template>

              <b-row class="p-2">

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="vehicle_name">Vehicle Name</label>
                    <b-input
                      id="vehicle_name"
                      v-model="reservationHistory.vehicle_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="vehicle_license_plate">License Plate</label>
                    <b-input
                      id="vehicle_license_plate"
                      v-model="reservationHistory.vehicle_license_plate"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="vehicle_registration_expiry">Registration Expiry</label>
                    <b-input
                      id="vehicle_registration_expiry"
                      v-model="reservationHistory.vehicle_registration_expiry"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="vehicle_seat_capacity">Seat Capacity</label>
                    <b-input
                      id="vehicle_seat_capacity"
                      v-model="reservationHistory.vehicle_seat_capacity"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>

          </b-tabs>
        </form>
      </b-card>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <!-- modal preview image -->
    <b-modal
      id="modal-preview-image"
      size="lg"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="`Preview Attachment [${file.name}]`"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SUserReservation } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'

export default {
  name: 'VUserHistories',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Reservation Histories'
    }
  },

  mixins: [formatter, url],

  data () {
    return {
      state: {
        file: {
          previewing: false
        }
      },
      file: {
        name: '',
        preview: null
      },
      list: {
        statuses: [
          { text: 'All', value: 'All' },
          { text: 'Approved', value: 'Approved' },
          { text: 'Disapproved', value: 'Disapproved' },
          { text: 'Cancelled', value: 'Cancelled' }
        ]
      },
      reservationHistory: {
        id: 0,
        created_at: '',
        status: '',
        user_name: '',
        company_name: '',
        organization_name: '',
        reservation_type: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        vehicle_type_name: '',
        passenger_count: 0,
        purpose: '',
        vehicle_name: '',
        vehicle_license_plate: '',
        vehicle_registration_expiry: '',
        vehicle_seat_capacity: 0,
        driver_name: '',
        driver_license_type: '',
        driver_license_number: '',
        driver_license_expiry: '',
        cancelled_at: '',
        cancellation_reason: '',
        processed_at: '',
        processed_by_name: '',
        processed_remarks: '',
        file_path: '',
        attachments: []
      },
      tableHistories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          status: 'All',
          date_from: '',
          date_to: '',
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          { key: 'action', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'reserved at', sortable: true },
          'reservation_type',
          { key: 'vehicle_type_name', label: 'Vehicle Type' },
          'departure',
          'arrival',
          'status'
        ]
      },
      tableAttachments: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  computed: {
    departureDateTime () {
      if (this.reservationHistory.departure_date !== '' && this.reservationHistory.departure_time !== '') {
        return this.dateTimeFormatterLong(`${this.reservationHistory.departure_date} ${this.reservationHistory.departure_time}`)
      }
      return ''
    },

    arrivalDateTime () {
      if (this.reservationHistory.arrival_date !== '' && this.reservationHistory.arrival_time !== '') {
        return this.dateTimeFormatterLong(`${this.reservationHistory.arrival_date} ${this.reservationHistory.arrival_time}`)
      }
      return ''
    },

    isDriverVisible () {
      return ['Service Driver', 'Service Driver And Vehicle'].includes(this.reservationHistory.reservation_type)
    },

    isVehicleVisible () {
      return ['Service Vehicle', 'Service Driver And Vehicle'].includes(this.reservationHistory.reservation_type)
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_from: filter.date_from,
        filter_to: filter.date_to,
        filter_status: filter.status,
        filter_text: filter.search
      })

      return await SUserReservation.histories(filters).then(
        ({ data }) => {
          this.tableHistories.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tableHistories.totalRows = 0
        return []
      })
    },

    onView (item) {
      this.reservationHistory.id = item.id
      this.reservationHistory.status = item.status
      this.reservationHistory.user_name = item.user_name
      this.reservationHistory.company_name = item.company_name
      this.reservationHistory.organization_name = item.organization_name
      this.reservationHistory.reservation_type = item.reservation_type
      this.reservationHistory.departure_date = item.departure_date
      this.reservationHistory.departure_time = item.departure_time
      this.reservationHistory.arrival_date = item.arrival_date
      this.reservationHistory.arrival_time = item.arrival_time
      this.reservationHistory.vehicle_type_name = item.vehicle_type_name
      this.reservationHistory.passenger_count = item.passenger_count
      this.reservationHistory.purpose = item.purpose
      this.reservationHistory.vehicle_name = item.vehicle_name
      this.reservationHistory.vehicle_license_plate = item.vehicle_license_plate
      this.reservationHistory.vehicle_registration_expiry = item.vehicle_registration_expiry
      this.reservationHistory.vehicle_seat_capacity = item.vehicle_seat_capacity
      this.reservationHistory.driver_name = item.driver_name
      this.reservationHistory.driver_license_type = item.driver_license_type
      this.reservationHistory.driver_license_number = item.driver_license_number
      this.reservationHistory.driver_license_expiry = item.driver_license_expiry
      this.reservationHistory.cancelled_at = item.cancelled_at !== null ? this.dateTimeFormatter(item.cancelled_at) : ''
      this.reservationHistory.cancellation_reason = item.cancellation_reason
      this.reservationHistory.processed_at = item.processed_at !== null ? this.dateTimeFormatter(item.processed_at) : ''
      this.reservationHistory.processed_by_name = item.processed_by_name
      this.reservationHistory.processed_remarks = item.processed_remarks
      this.reservationHistory.created_at = this.dateTimeFormatter(item.created_at)
      this.reservationHistory.file_path = item.file_path
      this.reservationHistory.attachments = item.attachments
      this.$bvModal.show('modal-view-form')
    },

    onPreviewAttachment (item) {
      this.file.name = item.file_original_name
      this.file.preview = this.getImageUrl(`${this.reservationHistory.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    }
  }
}
</script>
